import { memo, useCallback, useContext } from "react";
import { Store } from "store/store-reducer";
import { updateWallet } from "store/actions";
import cls from "./LoginButton.module.scss";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/shevron-arrow.svg";
import { Button, ButtonThemes } from "components/ui/Button/Button";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { classNames } from "utils/classNames";

const LoginButton = memo(() => {
  const { state, dispatch } = useContext(Store);
  const isLogged = state.wallet.connected;

  const loginHandler = useCallback(() => {
    alert("login");
  }, []);

  const onLogOut = () => {
    updateWallet(dispatch, {});
  };

  if (isLogged) {
    return (
      <div className={cls.loginButton}>
        <div className={cls.dropdown}>
          <div className={cls.dropdownTitle}>
            ID{state.wallet.id} <ArrowIcon />
          </div>
          <div className={cls.dropdownBody}>
            <ul className={cls.dropdownList}>
              <li>
                <button onClick={onLogOut} className={cls.dropdownButton}>
                  <LogoutIcon />
                  Log out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Button
      className={classNames(cls.loginButton, {}, ["coming-soon"])}
      disabled
      onClick={loginHandler}
      theme={ButtonThemes.Clear}
    >
      <UserIcon />
      <span>Login</span>
    </Button>
  );
});

export default LoginButton;
