export const MiningModeTypes = {
  LIGHT: "light",
  CASUAL: "casual",
  FULL: "full",
};

export const BalanceIncrement = {
  LIGHT: 0.0000000009,
  CASUAL: 0.0000000012,
  FULL: 0.0000000015,
};

export const MiningSpeed = {
  LIGHT: 80,
  CASUAL: 130,
  FULL: 180,
};
