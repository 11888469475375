import React, { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { MinerPageAsync as MinerPage } from "pages/MinerPage/MinerPage.async";
import { TermsPageAsync as TermsPage } from "pages/TermsPage/TermsPage.async";
import { PrivacyPageAsync as PrivacyPage } from "pages/PrivacyPage/PrivacyPage.async";
import { WalletPageAsync as WalletPage } from "pages/WalletPage/WalletPage.async";

const AppRouter = memo(() => (
  <Routes>
    <Route path="/" element={<MinerPage />} />
    <Route path="/terms" element={<TermsPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/wallet/*" exact element={<WalletPage />} />
  </Routes>
));

export default AppRouter;
