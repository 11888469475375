export const convertCoinToUSD = (amount, exchangeRate) => {
  const result = amount * exchangeRate;
  return result.toFixed(2);
};

export const convertUSDToCoin = (amount, exchangeRate) => {
  const result = amount / exchangeRate;
  return result.toFixed(6);
};

export const convertXMRToUSD = (exchangeRate, XMRBalance) => {
  const newUSDBalance = exchangeRate * XMRBalance;
  return newUSDBalance.toFixed(7) > 0 ? newUSDBalance.toFixed(7) : "0.0000001";
};
