import React, { memo } from "react";
import { classNames } from "utils/classNames";
import cls from "./Button.module.scss";

export const ButtonThemes = {
  Primary: "primary",
  PrimaryGreen: "primary-green",
  Outline: "outline",
  OutlineGreen: "outline-green",
  Clear: "clear",
};

export const ButtonStyles = {
  fullWidthMobile: "full-width-mobile",
  fullWidth: "full-width",
};

export const Button = memo((props) => {
  const {
    className,
    children,
    theme = ButtonThemes.Primary,
    variant,
    href,
    fullWidth,
    ...otherProps
  } = props;

  if (href) {
    return (
      <a
        href={href}
        className={classNames(cls.Button, {}, [
          className,
          cls[variant],
          cls[theme],
        ])}
        {...otherProps}
      >
        {children}
      </a>
    );
  }

  const mods = {
    [cls.fullWidth]: fullWidth,
  };

  return (
    <button
      className={classNames(cls.Button, mods, [
        className,
        cls[variant],
        cls[theme],
      ])}
      type="button"
      {...otherProps}
    >
      {children}
    </button>
  );
});
