import { ActionTypes } from "store/store-reducer";

export const updateIsMobile = (dispatch, isMobile) => {
  return dispatch({
    type: ActionTypes.IS_MOBILE_UPDATED,
    payload: isMobile,
  });
};

export const updateMiningMode = (dispatch, miningMode) => {
  return dispatch({
    type: ActionTypes.MINING_MODE_UPDATED,
    payload: miningMode,
  });
};

export const updateXMRBalance = (dispatch, balanceXMR) => {
  return dispatch({
    type: ActionTypes.XMR_BALANCE_UPDATED,
    payload: balanceXMR,
  });
};

export const updateUSDBalance = (dispatch, balanceUSD) => {
  return dispatch({
    type: ActionTypes.USD_BALANCE_UPDATED,
    payload: balanceUSD,
  });
};

export const updateIsMining = (dispatch, isMining) => {
  return dispatch({
    type: ActionTypes.IS_MINING_UPDATED,
    payload: isMining,
  });
};

export const updateWallet = (dispatch, wallet) => {
  return dispatch({
    type: ActionTypes.WALLET_UPDATED,
    payload: wallet,
  });
};
