import React from "react";
import cls from "./Loader.module.scss";
import { ReactComponent as LoadingIcon } from "assets/icons/loader.svg";
import { classNames } from "utils/classNames";

const Loader = () => {
  return (
    <div className={classNames(cls.Loader, {}, [])}>
      <LoadingIcon />
    </div>
  );
};

export default Loader;
