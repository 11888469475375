import { createContext, useReducer } from "react";
import { MiningModeTypes } from "config/miningParams";

export const ActionTypes = {
  IS_MOBILE_UPDATED: "IS_MOBILE_UPDATED",
  MINING_MODE_UPDATED: "MINING_MODE_UPDATED",
  XMR_BALANCE_UPDATED: "XMR_BALANCE_UPDATED",
  USD_BALANCE_UPDATED: "USD_BALANCE_UPDATED",
  IS_MINING_UPDATED: "IS_MINING_UPDATED",
  WALLET_UPDATED: "WALLET_UPDATED",
};

const initialState = {
  isAdvertising: false,
  isMobile: false,
  miningMode: MiningModeTypes.CASUAL,
  balanceXMR: 0.000000000017,
  exchangeRate: 165,
  balanceUSD: 0,
  isMining: false,
  wallet: {
    id: "492-388-329",
    connected: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.IS_MOBILE_UPDATED:
      return { ...state, isMobile: action.payload };
    case ActionTypes.MINING_MODE_UPDATED:
      return { ...state, miningMode: action.payload };
    case ActionTypes.XMR_BALANCE_UPDATED:
      return { ...state, balanceXMR: action.payload };
    case ActionTypes.USD_BALANCE_UPDATED:
      return { ...state, balanceUSD: action.payload };
    case ActionTypes.IS_MINING_UPDATED:
      return { ...state, isMining: action.payload };
    case ActionTypes.WALLET_UPDATED:
      return { ...state, wallet: action.payload };
    default:
      return state;
  }
};

export const Store = createContext(initialState);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};
