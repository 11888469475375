import cls from "./AppLink.module.scss";
import { classNames } from "utils/classNames";
import { memo } from "react";
import { Link } from "react-router-dom";

const AppLink = memo((props) => {
  const { className, onClick, to, children, ...otherProps } = props;

  const clickHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    onClick && onClick();
  };

  if (to) {
    return (
      <Link
        to={to}
        onClick={clickHandler}
        className={classNames(cls.AppLink, {}, [className])}
        {...otherProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <a className={classNames(cls.AppLink, {}, [className])} {...otherProps}>
      {children}
    </a>
  );
});

export default AppLink;
