import cls from "./Footer.module.scss";
import { classNames } from "utils/classNames";
import AppLink from "components/ui/AppLink/AppLink";
import { memo } from "react";

const Footer = memo((props) => {
  const { className } = props;

  return (
    <footer className={classNames(cls.Footer, {}, [className])}>
      <div className={classNames(cls.FooterBody, {}, ["container"])}>
        <div className={cls.copy}>Copyright © 2023. Coinvault LLC</div>
        <div className={cls.menu}>
          <AppLink to="/terms" className={cls.link}>
            Terms
          </AppLink>
          <AppLink to="/privacy" className={cls.link}>
            Privacy
          </AppLink>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
