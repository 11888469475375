import cls from "./Header.module.scss";
import LogoIcon from "assets/icons/logo.svg";
import { classNames } from "utils/classNames";
import AppLink from "components/ui/AppLink/AppLink";
import { ReactComponent as BurgerIcon } from "assets/icons/burger.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { memo, useContext, useEffect, useState } from "react";
import { Store } from "store/store-reducer";
import LoginButton from "./ui/LoginButton";
import { Link } from "react-router-dom";

const links = [
  { title: "Wallet", path: "/wallet", disabled: true },
  { title: "Miner", path: "/", disabled: false },
];

const Header = memo(({ className }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { state } = useContext(Store);
  const { isMobile } = state;

  useEffect(() => {
    menuOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [menuOpen]);

  const closeBurgerHandler = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  const onContentClick = (e) => {
    e.stopPropagation();
  };

  const onMenuToggle = () => {
    setMenuOpen((prevMenuState) => !prevMenuState);
  };

  return (
    <header
      onClick={closeBurgerHandler}
      className={classNames(cls.Header, { [cls.menuOpen]: menuOpen }, [
        className,
      ])}
    >
      <div className={classNames(cls.body, {}, ["container"])}>
        <Link to="/" className={cls.logo}>
          <img src={LogoIcon} alt="logo" />
          <span>coinvault</span>
        </Link>

        <div className={cls.menu} onClick={onContentClick}>
          <div className={cls.menuList}>
            {links.map((link) => (
              <AppLink
                key={link.path}
                disabled={link.disabled}
                to={link.path}
                onClick={closeBurgerHandler}
                className={classNames(cls.link, {}, ["coming-soon"])}
              >
                <span>{link.title}</span>
              </AppLink>
            ))}
          </div>
          {isMobile && <LoginButton />}
        </div>

        {!isMobile && <LoginButton />}

        <button onClick={onMenuToggle} className={cls.burgerButton}>
          {menuOpen ? <CrossIcon /> : <BurgerIcon />}
        </button>
      </div>
    </header>
  );
});
export default Header;
